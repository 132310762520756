import styled from '@emotion/styled';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import CardH from 'src/components/cards/card-h';
import CardV from 'src/components/cards/card-v';
import { IMAGE_SIZE } from 'src/components/cards/image';
import WidgetNewsPaper from 'src/components/widget/widget-newspaper';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { PSection } from 'src/interface/section';
import { GET } from 'src/services';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { convertDatasets } from 'src/utils/datasets';
import useResponsive from 'src/utils/devices';
import useSWR from 'swr';
import SectionEvent from '../section-event/section-event';
const SectionHeader = dynamic(import('src/components/sections/section-header'));

type Props = {
  headerLatestNews: PSection['headerLatestNews'];
  block1: PSection['block1'];
  block2: PSection['block2'];
  block3: PSection['block3'];
  eventDataLayer?: IDataLayerGlobal;
  datalockII?: any;
};

const SectionTwo = ({ datalockII, headerLatestNews, block1, block2, block3, eventDataLayer }: Props) => {
  const dataLatestNews = headerLatestNews?.data;
  // const fetcher = (url: any) => GET({ url });
  const fetcherExternal = (url: any) => GET({ url, external: false, isClient: false });
  // https://api.climatecenterthailand.co/api/v1.0/categories/latest-news
  const sectionClimate = useSWR(`/api/v1.0/categories/sustainable`, fetcherExternal, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  });
  const sectionAmerican = useSWR(`/api/datasets/21`, fetcherExternal, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  });


  const blockClimate = sectionClimate?.data ?? [];
  const { isMobile } = useResponsive();

  return (
    <>
      {(!_.isEmpty(sectionAmerican) && !_.isEmpty(sectionAmerican?.data?.[0])) && (
        <>
          <SectionEvent
            external={false}
            id='section-american'
            link={convertDatasets(sectionAmerican?.data?.[0]?.dataSet, 'link', true)}
            data={datalockII}
            heading={'AMERICAN POWER 2024'}
            color={'#000'}
            image={convertDatasets(sectionAmerican?.data?.[0]?.dataSet, 'background', true)}
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'section-american',
              position: 'ใต้:section-hightligth',
              data: {
                block: '2',
                title: 'อ่านทั้งหมด',
                heading: 'AMERICAN POWER 2024'
              }
            }}
          />
        </>
      )}
      {!_.isEmpty(dataLatestNews) && (
        <SectionWrapper id={`section-2`} className={`container latest-news`} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
          <SectionHeader data={headerLatestNews?.title} link={headerLatestNews?.link} showReadmore={false} />
          <div className='card-list'>
            {_.map(dataLatestNews, (item, i) => {
              if (i < 6)
                return (
                  <div className='card-item' key={item.id}>
                    <CardV
                      data={item}
                      showBadge={false}
                      showBlurb={false}
                      showFooter={false}
                      showCountView={true}
                      size={IMAGE_SIZE.MD_WEBP}
                      // ! DATALAYER
                      eventDataLayer={{
                        ...eventDataLayer,
                        section: 'section-2',
                        position: 'ใต้:section-1',
                        data: {
                          title: item?.title,
                          heading: headerLatestNews?.title,
                          index: i + 1,
                          ...eventDataLayer?.data
                        }
                      }}
                    />
                  </div>
                );
            })}
          </div>
        </SectionWrapper>
      )}
      <SectionWrapper id={`section-3`} className={`container grid-block`}>
        {!_.isEmpty(block1) && (
          <div className='block-1'>
            <SectionEvent
              id={`section-${block1?.title}`}
              isHref={false}
              data={block1.data.slice(0, 4)}
              heading={block1?.title}
              link={block1.link}
              color={'#BCBCBC'}
              image={APP_IMG + '/images/events/than_exclusive.webp'}
              child={
                <CardH
                  data={block1.data[0]}
                  showBadge={false}
                  showBlurb={true}
                  showFooter={false}
                  showPublishDate={true}
                  // ! DATALAYER
                  size={IMAGE_SIZE.MD_WEBP}
                  eventDataLayer={{
                    ...eventDataLayer,
                    section: 'section-3',
                    position: 'ใต้:section-2',
                    data: {
                      block: '1',
                      title: block1?.title,
                      heading: block1?.title,
                      index: 1,
                      ...eventDataLayer?.data
                    }
                  }}
                />
              }
              // ! DATALAYER
              eventDataLayer={{
                ...eventDataLayer,
                section: 'section-3',
                position: 'ใต้:section-2',
                data: {
                  block: '2',
                  title: block1?.title,
                  heading: block1?.title,
                  ...eventDataLayer?.data
                }
              }}
            />
          </div>
        )}

        {!_.isEmpty(block2) && (
          <div className='block-2'>
            <SectionHeader data={block2?.title} link={block2?.link} showReadmore={!!isMobile} border='right' />
            {block2.data.map((item, i) => {
              if (i < 1)
                return (
                  <CardV
                    key={item.id}
                    data={item}
                    showBadge={false}
                    showBlurb={false}
                    showCenter={true}
                    // ! DATALAYER
                    eventDataLayer={{
                      ...eventDataLayer,
                      section: 'section-3',
                      position: 'ใต้:section-2',
                      data: {
                        block: '2',
                        title: item?.title,
                        heading: block2?.title,
                        index: i,
                        ...eventDataLayer?.data
                      }
                    }}
                  />
                );
            })}
            {block2?.data?.map(
              (item, index) =>
                index > 0 && (
                  <CardH
                    key={item.id}
                    data={item}
                    showBadge={false}
                    showBlurb={false}
                    // ! DATALAYER
                    eventDataLayer={{
                      ...eventDataLayer,
                      section: 'section-3',
                      position: 'ใต้:section-2',
                      data: {
                        block: '3',
                        title: item?.title,
                        heading: block2?.title,
                        index: index + 1,
                        ...eventDataLayer?.data
                      }
                    }}
                  />
                )
            )}
            {!isMobile && (
              <Link href={`${block2.link}`} passHref>
                <div
                  className='readmore'
                  // ! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      ...eventDataLayer,
                      section: 'section-3',
                      position: 'ใต้:section-2',
                      data: {
                        block: '2',
                        title: 'อ่านทั้งหมด',
                        heading: block2?.title
                      }
                    })
                  }
                >
                  <small>อ่านทั้งหมด</small>
                </div>
              </Link>
            )}
          </div>
        )}

        <div className='block-3'>
          {!_.isEmpty(block3) && (
            <>
              <SectionHeader data={block3?.title} link={block3?.link} showReadmore={!!isMobile} border='right' />
              <CardV
                data={block3?.data[0]}
                showBadge={false}
                showBlurb={false}
                showCenter={true}
                // ! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  section: 'section-3',
                  position: 'ใต้:section-2',
                  data: {
                    block: '3',
                    title: block3?.data[0]?.title,
                    heading: block2?.title,
                    index: 1,
                    ...eventDataLayer?.data
                  }
                }}
              />
              {block3?.data?.map(
                (item, index) =>
                  index > 0 && (
                    <CardH
                      key={item.id}
                      data={item}
                      showBadge={false}
                      showBlurb={false}
                      // ! DATALAYER
                      eventDataLayer={{
                        ...eventDataLayer,
                        section: 'section-3',
                        position: 'ใต้:section-2',
                        data: {
                          block: '3',
                          title: item?.title,
                          heading: block2?.title,
                          index: index + 1,
                          ...eventDataLayer?.data
                        }
                      }}
                    />
                  )
              )}
              {!isMobile && (
                <Link href={`${block3.link}`} passHref>
                  <div
                    className='readmore'
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        section: 'section-3',
                        position: 'ใต้:section-2',
                        data: {
                          block: '3',
                          title: 'อ่านทั้งหมด',
                          heading: block3?.title
                        }
                      })
                    }
                  >
                    <small>อ่านทั้งหมด</small>
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
        <div className='block-newspaper'>
          <WidgetNewsPaper
            id='widget-newspaper'
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'section-3',
              position: 'ใต้:section-2',
              data: {
                title: 'หนังสือพิมพ์',
                heading: 'หนังสือพิมพ์',
                ...eventDataLayer?.data
              }
            }}
          />
        </div>
      </SectionWrapper>
      {!_.isEmpty(blockClimate) && (
        <>
          <SectionEvent
            external={false}
            id='section-climate-center'
            link={'/category/sustainable'}
            data={blockClimate}
            heading={'CLIMATE CENTER'}
            color={'#0a712f'}
            image={APP_IMG + '/images/events/climate-center.webp'}
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'section-climate-center',
              position: 'ใต้:section-3',
              data: {
                block: '2',
                title: 'อ่านทั้งหมด',
                heading: 'Climate Center'
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default SectionTwo;

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  .heading {
    justify-content: flex-end !important;
  }

  .block-1,
  .block-2,
  .block-3 {
    .heading-active {
      width: 100%;
    }
  }
  .block-1 {
    border: none;
    /* .card-full {
      border-top-right-radius: 38px;
    } */

    .grid-section {
      background: linear-gradient(191deg, #dfdfdf 30%, rgb(255 255 255) 100%);
      .block-2 {
        border: none;
      }
    }
  }

  .block-newspaper {
    padding-top: 40px;
    margin: auto;
    max-width: 300px;
    img {
      box-shadow: 0px 2px 16px 0px rgba(255, 133, 0, 0.6);
    }
  }
  &.latest-news {
    padding: 30px 15px;

    .card-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 0px;
      .card-item {
        border: 1px solid var(--border-color);
      }
      .card-v .card-image {
        padding-top: 56%;
      }
      border-bottom: 5px solid var(--tertiary-color);

      @media (max-width: 769px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
      }
      @media (max-width: 690px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
    }
  }

  &.grid-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding-bottom: 15px;
    grid-template-areas:
      'block-1 block-1 block-1'
      'block-2 block-3 block-newspaper';
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'block-1'
        'block-2'
        'block-3';
    }
    .block-1 {
      grid-area: block-1;
    }
    .block-2 {
      grid-area: block-2;
      padding-right: 15px;
      border-right: 1px solid var(--border-color);
      @media (max-width: 769px) {
        border: none;
        padding: 0;
      }
    }
    .block-3 {
      grid-area: block-3;
      border-right: 1px solid var(--border-color);
      padding-right: 15px;
      @media (max-width: 769px) {
        border: none;
        padding: 0;
      }
    }
    .block-newspaper {
      max-width: 100%;
      padding: 0;
      margin-top: 55px;
    }
    .card-v {
      .card-detail {
        padding: 8px 0;
        .title {
          font-size: 22px;
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
        .published-date {
          margin: 0;
        }
      }
      .card-image {
        padding-top: 56.5%;
        /* border-bottom-right-radius: 38px; */
      }
    }
    .block-2 {
      .card-h {
        .card-detail {
          .title {
            color: var(--black-color) !important;
            font-size: 16px;
          }
          .published-date small {
            color: rgb(255 133 2) !important;
          }
        }
        .card-image {
          padding-top: 50%;
          /* border-bottom-right-radius: 28px; */
        }
      }
    }
  }
`;
